<template>
    <div>
        <Teleport to="body">
            <div class="modal-wrapper">
                <Transition name="dialog"
                            appear>
                    <div class="modal-transition modal d-flex justify-content-center align-items-center"
                         :class="{ 'newsletter': subscribeModalStore.isNewsletter }"
                         @click="subscribeModalStore.setSubscribeSuccessModal(false)">
                        <div class="modal-wrapper">
                            <div class="modal-content justify-content-between justify-content-md-start"
                                 @click.stop>
                                <div class="text-left">
                                    <span class="close-button"
                                          @click="subscribeModalStore.setSubscribeSuccessModal(false)">
                                        &times;
                                    </span>
                                </div>
                                <div class="text-center">
                                    <img class="thank-you-img mx-auto mb-3"
                                         :src="asset_cdn('crypto-prices/assets/icons/thank-you.png')"
                                         :alt="getTrans('subscribe.thankYouTitle', 'You are subscribed to get notified after new features are unlocked.')">
                                    <template v-if="!props.promoCode">
                                        <div class="modal-title text-center mb-3 mt-1 mt-md-0 text-white">
                                            {{ title }}
                                        </div>
                                        <div class="modal-subtitle text-center text-white">
                                            {{ subtitle }}
                                        </div>
                                    </template>
                                    <div v-else>
                                        <h3 class="my-2 text-center text-white">
                                            {{ props.promoCode.successPopupTitle }}
                                        </h3>
                                        <p class="discount-subtitle mb-3 mt-1 text-center text-white">
                                            {{ props.promoCode.successPopupSubtitle }}
                                        </p>
                                        <div class="my-4 pt-2 text-center">
                                            <code class="mb-5">{{ props.promoCode.promoCode }}</code>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-block d-md-none pt-2 pt-md-0">
                                    <button class="quiz-button--inverted w-100"
                                            @click="subscribeModalStore.setSubscribeSuccessModal(false)">
                                        <Translate translationKey="quests-dashboard.close"
                                                   fallback="Close" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </Teleport>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useSubscribeModalStore } from '@/js/store/subscribe/SubscribeModalStore';
    import { EventTypeEnum, KeyEnum } from '@/js/enums/enums';
    import { asset_cdn } from '@/js/helpers/helpers';
    import { getTrans } from '@/js/helpers/getTrans';
    import { PromoCode } from '../types/types';
    import { useEventListener } from '@/js/composables/useEventListener';

    const props = defineProps({
        promoCode: {
            type: Object as () => PromoCode | null,
            required: false,
            default: null,
        },
    });

    const subscribeModalStore = useSubscribeModalStore();

    const DEFAULT_TITLE = getTrans('subscribe.thankYouTitle', 'You are subscribed to get notified after new features are unlocked.');
    const DEFAULT_SUBTITLE = getTrans('subscribe.thankYouSubtitle', 'Thank you for subscribing!');

    const NEWSLETTER_TITLE = getTrans('subscribe.newsletterThankYouTitle', 'Thanks! You\'re in for a juicy crypto treat');
    const NEWSLETTER_SUBTITLE = getTrans('subscribe.newsletterThankYouSubtitle', 'New to BitDegree? Check your inbox to confirm your subscription. Otherwise, sit tight - fresh content is headed your way.');

    const title = computed(() => (subscribeModalStore.isNewsletter ? NEWSLETTER_TITLE : DEFAULT_TITLE));
    const subtitle = computed(() => (subscribeModalStore.isNewsletter ? NEWSLETTER_SUBTITLE : DEFAULT_SUBTITLE));

    const onEscapePressed = (event: KeyboardEvent) => {
        if (event.key === KeyEnum.ESCAPE || event.key === KeyEnum.ESC || event.which === 27) {
            subscribeModalStore.setSubscribeSuccessModal(false);
        }
    };

    useEventListener(window, EventTypeEnum.KEYDOWN, onEscapePressed);
</script>

<style scoped lang="scss">
@import "@/sass/partials/z-index";
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";

.modal-transition {
    &.modal {
        z-index: $modal-transition-max-z-index;
    }

    &.newsletter {
        .thank-you-img {
            width: 80px;
        }
    }

    .modal-wrapper {
        max-width: 600px;
    }

    .modal-content {
        padding: 27px;

        @media (max-width: $md-breakpoint-max-width) {
            padding: 0 16px 16px 16px;
        }
    }

    .modal-title {
        padding-right: 24px;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
    }

    .modal-subtitle {
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
    }

    .thank-you-img {
        width: 120px;
    }

    code {
        padding: 15px 30px;
        border: 2px dashed $error400;
        font-size: 24px;
    }
}
</style>
